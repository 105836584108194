@import "../../assets/variables";

.btn {
  width: 100%;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 25px;
  padding: 12px;
  transition: all 0.4s;
  color: $color;
  outline: 0;
  border: none;
}

.btn:hover {
  box-shadow: none;
  color: $color;
}

.btn-primary {
  background-color: $background;
  box-shadow: 0px 9px 30px 0px rgb(210 15 15 / 40%);
}

.btn-secondary {
  box-shadow: 0px 9px 30px 0px rgb(33 33 33 / 40%);
  opacity: 0.65;
  background-color: $color-text2;
}
