@import "../../assets/variables";

.card-section {
  width: 100%;
  padding: 10px;
  margin: 16px 0;

  .location-box {
    max-width: 100%;
    padding: 30px;
    background-color: $color;
    border-radius: 30px;
    box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;

    p {
      margin-bottom: 0.25rem;

      small {
        font-size: 14px;
      }
    }
  }

  &:hover,
  &:hover h5 {
    color: $background;
  }
}

@media (min-width: 576px) {
  .card-section {
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .card-section {
    max-width: 25%;
  }
}
