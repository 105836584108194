@import "../../assets/variables";

.check-in {
  display: flex;
  justify-content: center;

  .location-box {
    margin: 16px 0;
    width: 100%;
    padding: 30px;
    background-color: $color;
    border-radius: 30px;
    box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;

    h5 {
      margin-bottom: 3rem;
    }

    h6,
    p {
      font-weight: 300;
      margin-bottom: 1rem;
    }
    .failed {
      color: $background;
    }
  }

  @media (min-width: 768px) {
    .location-box {
      width: 50%;
    }
  }

  .mb-4 {
    margin-bottom: 1.5rem;
  }
}
