@import "../../assets/variables";

.detail {
  display: flex;
  justify-content: center;

  .location-box {
    margin: 16px 0;
    width: 100%;
    padding: 30px;
    background-color: $color;
    border-radius: 30px;
    box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;

    h1 {
      font-size: 65px;
    }

    h6 {
      font-weight: 300;
      margin-bottom: 1rem;
    }

    .button-section {
      width: 100%;
      display: flex;
      gap: 1rem;
      justify-content: space-around;
      * {
        flex: 1 1;
      }
    }
  }

  @media (min-width: 768px) {
    .location-box {
      width: 50%;
    }
  }
}
