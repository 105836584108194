@import "./assets/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;

  font-size: 16px;
  line-height: 1.875;
  font-weight: 400;
  color: $color-text;
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
  color: $color-text;
}

h5 {
  line-height: 30px;
  font-size: 1.25rem;
}

h6,
p {
  font-size: 1rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
