@import "../../assets/variables";

.material-textfield {
  position: relative;
  margin: 16px 0;

  label {
    position: absolute;
    font-size: 1rem;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color;
    color: $color-text2;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
  input {
    width: 100%;
    font-size: 1rem;
    outline: none;
    border: 1px solid $color-text2;
    border-radius: 25px;
    padding: 1rem 0.7rem;
    color: $color-text2;
    transition: 0.1s ease-out;
    &:focus {
      border-color: $background;
    }
    &:focus + label {
      color: $background;
      top: 0;
      transform: translateY(-30%) scale(0.9);
    }
    &:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-30%) scale(0.9);
    }
  }
  p {
    color: $background;
    padding: 5px;
  }
}
